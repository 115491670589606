import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`コンプ、EQ、リバーブ全てに言える事ですが、かけない楽器があってもOKです。つまり、今回はセンド量が０の楽器があっても構いません。`}<br parentName="p"></br>{`
`}{`必要なものにだけ、必要なことをします。当たり前ですが。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      